import React from "react";

function SingleService(props) {
  let bgColor = props.background;
  return (
    <div className="flex justify-center">
      <div
        className={`block p-6 rounded-lg ${bgColor} max-w-sm hover:bg-white hover:scale-105 hover:shadow-sm transition duration-300 ease-in-out`}>
        <img src={props.icon} alt="" className=" w-20  " />
        <h5 className="text-gray-900 text-xl leading-tight font-medium py-3">
          {props.title}
        </h5>
        <p className="text-gray-700 text-base mb-4">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
        <button
          type="button"
          className=" inline-block px-6 py-2.5 bg-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-money-green-1 hover:text-white hover:shadow-lg focus:bg-money-green-1 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
          View Service
        </button>
      </div>
    </div>
  );
}

export default SingleService;
