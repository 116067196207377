import SingleService from "./singleService";
import HeadingTitle from "../../UI/heading";
import serviceImage from "../../assets/images/services-image2.jpg";
import smallBusiIcon from "../../assets/icons/financial-presentation.png";
import taxicon from "../../assets/icons/financial.png";
import bankIcon from "../../assets/icons/bank.png";
import taxIncome from "../../assets/icons/accounting.png";
import reporting from "../../assets/icons/report.png";
import saving from "../../assets/icons/saving.png";

const ServiesCard = () => {
  return (
    <div>
      <HeadingTitle title="Servies" />
      <div className="flex gap-7">
        <div
          className="relative overflow-hidden bg-no-repeat bg-cover max-w-sm rounded-xl"
          style={{ backgroundPosition: "50%" }}>
          <img src={serviceImage} alt="" className="mx-w-xs" />
          <div
            className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
            <div class="flex justify-center items-center h-full w-full p-5 text-center ">
              <div>
                <h2 className="text-2xl font-semibold text-white mb-5">
                  Our Services
                </h2>
                <p class="text-white opacity-100">Can you see me?</p>
                <p class="text-white opacity-100">
                  Can you see me and the rest was just anothe history ishh?
                </p>

                <button className="mt-10 p-3 rounded-lg bg-money-green-1 text-white w-3/4">
                  Explore all Services
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <SingleService
            background="bg-yellow-50"
            title="Income tax calculation"
            icon={taxIncome}
          />
          <SingleService
            background="bg-emerald-50"
            title="Small Business"
            icon={smallBusiIcon}
          />
          <SingleService
            background="bg-lime-50"
            title="Book keeping and"
            icon={taxicon}
          />
          <SingleService
            background="bg-green-50"
            title="Tax issues"
            icon={bankIcon}
          />
          <SingleService
            background="bg-teal-50"
            title="Finanacial reports"
            icon={reporting}
          />
          <SingleService
            background="bg-green-100"
            title="Financial Savings"
            icon={saving}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiesCard;
