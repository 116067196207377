import React from "react";

const HeadingTitle = (props) => {
  return (
    <>
      <div className="py-10">
        <div className="text-3xl font-semibold text-gray-500 text-left uppercase">
          {props.title}
        </div>
        <div className="relative flex  bg-white w-full h-1 mt-2">
          <div className="absolute w-10 h-1 bg-money-green-1"></div>
        </div>
      </div>
    </>
  );
};

export default HeadingTitle;
