import React from "react";

const ContaactUs = () => {
  return (
    <div className=" content-container" style={{ height: "78vh" }}>
      Conract us page
    </div>
  );
};

export default ContaactUs;
