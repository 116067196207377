import HeadingTitle from "../UI/heading";
import ImageWithTextRight from "../UI/image-with-text-right";

const Benefits = (props) => {
  return (
    <div className="mt-14">
      <HeadingTitle title="The Benefits" />
      <ImageWithTextRight
        benefitImage={props.benefitImage}
        benefitText={props.benefitText}
      />
    </div>
  );
};

export default Benefits;
