import React from "react";

const Footer = () => {
  return (
    <div className="bg-gray-800 text-gray-300 ">
      <div className="content-container flex justify-around pt-10">
        <div className="felx">
          <h4 className="text-md font-medium uppercase">Contact Info</h4>
          <ul>
            <li>About us</li>
            <li>Contact us</li>
          </ul>
        </div>
        <div className="felx">
          <h4 className="text-md font-medium uppercase">Quick Links</h4>
        </div>
        <div className="felx">
          <h4 className="text-md font-medium uppercase">Information</h4>News
        </div>
        <div className="felx">
          <h4 className="text-md font-medium uppercase"> Be informed</h4>
          <div className="w-full flex">
            <input
              type="email"
              name=""
              id=""
              className="rounded-l-sm bg-gray-200 px-3 py-2 "
              placeholder="exmaple@email.com"
            />
            <button
              className="flex-1 text-white bg-money-green-1 px-3 rounded-r-sm cursor-pointer"
              type="submit">
              Sign up
            </button>
          </div>
        </div>
      </div>
      <div className="text-gray-400 text-sm text-center pb-5">
        Copy &copy; 2022 Tyla accounting
      </div>
    </div>
  );
};

export default Footer;
