import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Nav from "./components/navigation/navigation";
import ContaactUs from "./views/contact-us/contactUs";
import Home from "./views/header/home";
import Pricing from "./views/pricing/pricing";
import Services from "./views/services/services";
import ModalOverlay from "./UI/modalOverlay";
import Footer from "./components/navigation/footer";

function App() {
  const [showModal, setShowModal] = useState(false);
  // const [bgopacity, setbgopacity] = useState("bg-opacity-90");

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <ModalOverlay handleShowModal={toggleModal} modalHide={showModal} />
      <Nav handleShowModal={toggleModal} />
      <div className="main">
        <Routes>
          <Route path="/" element={<Home showModal={showModal} />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<ContaactUs />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
