import React from "react";
import ReactDOM from "react-dom";
import ContactForm from "../components/forms/contactForm";

const Backdrop = (props) => {
  let shocase = props.modalHide
    ? "fixed right-0 left-0 bottom-0 top-0 w-screen h-screen flex items-center justify-center  bg-gray-900 bg-opacity-90 transition-all ease-out duration-1000 z-10"
    : "bg-gray-100 bg-opacity-100 transition-all ease-out duration-1000 z-10";
  return (
    <div
      className={shocase}
      onClick={() => {
        props.onConfirm();
      }}
    />
  );
};

const ModalOverlayContent = (props) => {
  return (
    <ContactForm handleShowModal={props.onShow} modalHide={props.modalHide} />
  );
};

const ModalOverlay = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop
          onConfirm={props.handleShowModal}
          modalHide={props.modalHide}
        />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlayContent
          onShow={props.handleShowModal}
          modalHide={props.modalHide}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default ModalOverlay;
