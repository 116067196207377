const ContactForm = (props) => {
  let shocase = props.modalHide
    ? "absolute left-1/2 overflow-hidden transition-all ease-out duration-1000 z-20"
    : "absolute -left-full overflow-hidden transition-all ease-out duration-1000 z-20";

  let translate = props.modalHide
    ? "translate(-50%, 10%)"
    : "translate(-1000%, -10%)";
  return (
    <div className={shocase} style={{ transform: translate }}>
      <form onSubmit={""}>
        <div class="block p-6 rounded-lg shadow-lg bg-money-green-2 w-450">
          <h5 class="uppercase text-gray-900 text-xl leading-tight font-bold">
            Ask Brian Something
          </h5>
          <p class="text-black text-base mb-4">
            Brian has the Answer just try him 😁
          </p>
          <div className="relative w-xl mb-3">
            <label
              className="block uppercase text-black text-xs font-bold mb-2"
              htmlFor="salary">
              Full Name
            </label>
            <input
              type="text"
              className="border-0 px-3 py-3 w-full placeholder-gray-400 text-black bg-white rounded text-sm shadow focus:outline-none focus:ring"
              placeholder="Enter full name"
              name="fullName"
              style={{ transition: "all .15s ease" }}
              onChange={""}
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-black text-xs font-bold mb-2"
              htmlFor="salary">
              Phone Number
            </label>
            <input
              type="number"
              className="border-0 px-3 py-3 placeholder-gray-400 text-black bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              placeholder="Phone Number"
              name="phone"
              style={{ transition: "all .15s ease" }}
              onChange={""}
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-black text-xs font-bold mb-2"
              htmlFor="fullName">
              Email
            </label>
            <input
              type="email"
              className="border-0 px-3 py-3 placeholder-gray-400 text-black bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              placeholder="Email"
              name="email"
              style={{ transition: "all .15s ease" }}
              onChange={""}
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-black text-xs font-bold mb-2"
              htmlFor="email">
              Message
            </label>
            <textarea className="w-full" name="" id="" rows="4"></textarea>
          </div>
          <div className="text-right">
            <button
              type="button"
              class="inline-block px-6 py-2 mr-2 bg-money-green-1 text-white font-medium text-xs rounded tracking-wider shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-900 active:shadow-lg transition duration-500 ease-in-out">
              Ask Brian
            </button>
            <button
              onClick={() => {
                props.handleShowModal();
              }}
              type="button"
              class="inline-block px-6 py-2 mr-2  text-black font-medium text-xs rounded tracking-wider shadow-md hover:bg-green-700 hover:text-white hover:shadow-lg focus:bg-green-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-900 active:shadow-lg transition duration-500 ease-in-out">
              Maybe Later
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
