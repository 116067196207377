const BannerText = () => {
  return (
    <div className="flex items-center ">
      <div className="relative flex flex-col  break-words w-full mb-6 p-5 lg:p-10 ">
        <div className="mt-6">
          <h1 className="text-white font-semibold text-5xl">
            Your story starts with us.
          </h1>
          <p className="mt-4 text-lg text-gray-300">
            This is where we show you how the Governments eats your money and
            you just happily slave away. Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Est laboriosam praesentium aspernatur ratione illo
            fugit saepe necessitatibus ipsam eius pariatur? Lorem ipsum dolor
            sit amet consectetur adipisicing elit. Reiciendis deleniti odit
            necessitatibus?
          </p>
        </div>
      </div>
    </div>
  );
};
export default BannerText;
