import HeadingTitle from "../UI/heading";
import sampleLogo from "../assets/logos/members-logos/sample-logo.png";

const MembersAssosiation = () => {
  return (
    <div className="text-center py-3 content-container">
      {/* <HeadingTitle title="Memebers Association" /> */}
      <div className="flex justify-between gap-5  grayscale ">
        <img src={sampleLogo} alt="" />
        <img src={sampleLogo} alt="" />
        <img src={sampleLogo} alt="" />
        <img src={sampleLogo} alt="" />
        <img src={sampleLogo} alt="" />
        <img src={sampleLogo} alt="" />
      </div>
    </div>
  );
};

export default MembersAssosiation;
