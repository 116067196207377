const TaxSummaryCard = (props) => {
  const showBox = props.onShowSummary;
  const taxInfom = props.taxInfo;

  const downloadPdfHandler = () => {
    alert("Donload PDF");
  };

  const sendEmailHandler = () => {
    alert("Send Email here");
  };

  const hideSummary = () => {
    props.onHideSummary();
  };

  if (!showBox) {
    return <></>;
  }
  return (
    <div className="flex flex-wrap relative pl-4 shrink-0">
      <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-money-green-2 p-5">
        <div
          className="absolute right-4 top-0 text-money-green-1 hover:text-gray-900 text-m cursor-pointer"
          onClick={hideSummary}>
          X
        </div>
        <h4 className="text-2xl font-semibold">
          Income Tax Calculation Summary
        </h4>
        <p className="leading-relaxed mt-1 mb-4 text-gray-600">
          The bellow info is 123456789 and so its yeah.. <br />
        </p>
        {taxInfom.map((info) => (
          <>
            <div>{info.yearTaxed}</div>
            <div>{info.salarytotal}</div>
            <div>{info.cyclePay}</div>
            <div>{info.groupAge}</div>
          </>
        ))}
        <div className="bg-gray-900 rounded text-slate-200 mb-8 mt-4">
          <p className="text-3xl font-bold  py-4 text-center">
            <span className="block text-base text-slate-300 ">
              Take home pay
            </span>
            R 17 498.00
          </p>
        </div>
        <div className="text-white">
          <div className=" flex justify-between p-2 rounded  bg-cyan-800 ">
            <p className="pr-6 font-bold">Taxable income </p>
            <p>R 24 000.00</p>
          </div>
          <div className=" flex justify-between p-2 rounded mt-1 bg-cyan-700 ">
            <p className="pr-6 font-bold"> Taxable income for the year </p>
            <p>R 24 000.00</p>
          </div>
          <div className=" flex justify-between p-2 rounded mt-1 bg-cyan-800 ">
            <p className="pr-6 font-bold">Tax you will pay / PAYE </p>
            <p>R 24 000.00</p>
          </div>
          <div className=" flex justify-between p-2 rounded mt-1 bg-cyan-700 ">
            <p className="pr-6 font-bold">Take home pay </p>
            <p>R 24 000.00</p>
          </div>
        </div>
        <div className="flex text-xs text-gray-600 mt-2">
          <div
            className="mr-5 hover:text-gray-900 cursor-pointer"
            onClick={downloadPdfHandler}>
            Download as pdf
          </div>
          <div
            className="mr-5 hover:text-gray-900 cursor-pointer"
            onClick={sendEmailHandler}>
            Send to Email
          </div>
        </div>

        <div className="text-right mt-7">
          <button
            className=" bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            type="button"
            style={{ transition: "all .15s ease" }}>
            More Details
          </button>
        </div>
      </div>
    </div>
  );
};
export default TaxSummaryCard;
