import { Link } from "react-router-dom";

const Nav = (props) => {
  const handleShowModal = () => {
    props.handleShowModal();
  };
  return (
    <div
      className=" content-container flex justify-between items-center text-m text-gray-500 font-semibold"
      style={{ minHeight: "8vh" }}>
      <div>
        <Link to="/"> Logo</Link>
      </div>
      <div className="flex items-center">
        <div className="mr-5">
          <Link to="/services" className="mr-6 hover:text-gray-800 ">
            Services
          </Link>
          <Link to="/pricing" className="mr-6 hover:text-gray-800">
            Pricing
          </Link>
          <Link to="/contact-us" className="mr-6 hover:text-gray-800">
            Contact us
          </Link>
        </div>

        <button
          className=" px-4 py-2 text-sm font-medium text-white uppercase transition-colors duration-200 transform bg-money-green-1 rounded-md lg:w-auto hover:bg-green-800 focus:outline-none focus:bg-green-800"
          onClick={handleShowModal}>
          Contact Brian
        </button>
      </div>
    </div>
  );
};

export default Nav;
