import React from "react";

const ImageWithTextRight = (props) => {
  let texts = props.benefitText;
  return (
    <>
      <div className="flex justify-between pb-10 gap-10">
        <div className="flex-initial w-full">
          <img
            className="rounded-lg"
            src={props.benefitImage}
            height="350px"
            alt=""
          />
        </div>
        <div className="flex-initial w-full ">
          <div className="py-4 pl-4 scale-100 bg-white rounded-lg mb-3 shadow-md hover:bg-money-green-2 hover:scale-105 hover:shadow-lg active:bg-green-900 active:shadow-lg transition duration-300 ease-in-out ">
            {texts.benefit1}
          </div>
          <div className="py-4 pl-4 scale-100 bg-white rounded-lg mb-3 shadow-md hover:bg-money-green-2 hover:scale-105 hover:shadow-lg active:bg-green-900 active:shadow-lg transition duration-300 ease-in-out ">
            {texts.benefit2}
          </div>
          <div className="py-4 pl-4 scale-100 bg-white rounded-lg mb-3 shadow-md hover:bg-money-green-2 hover:scale-105 hover:shadow-lg active:bg-green-900 active:shadow-lg transition duration-500 ease-in-out ">
            {texts.benefit3}
          </div>
          <div className="py-4 pl-4 scale-100 bg-white rounded-lg mb-3 shadow-md hover:bg-money-green-2 hover:scale-105 hover:shadow-lg active:bg-green-900 active:shadow-lg transition duration-500 ease-in-out ">
            {texts.benefit4}
          </div>
          <div className="py-4 pl-4 scale-100 bg-white rounded-lg mb-3 shadow-md hover:bg-money-green-2 hover:scale-105 hover:shadow-lg active:bg-green-900 active:shadow-lg transition duration-500 ease-in-out ">
            {texts.benefit5}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageWithTextRight;
