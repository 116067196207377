import { useState } from "react";

const IncomeTaxForm = (props) => {
  const [taxYear, setTaxtYear] = useState();
  const [totalSalary, setTotalSalary] = useState();
  const [payCycle, setPaycycle] = useState();
  const [ageGroup, setAgeGroup] = useState("");

  const taxYearHandle = (event) => {
    setTaxtYear(event.target.value);
  };

  const totalSalaryHandler = (event) => {
    setTotalSalary(event.target.value);
  };

  const payCycleHandler = (event) => {
    setPaycycle(event.target.value);
  };

  const ageGroupHandler = (event) => {
    setAgeGroup(event.target.value);
    console.log(ageGroup);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    let incomeInfo = {
      yearTaxed: taxYear,
      salarytotal: totalSalary,
      cyclePay: payCycle,
      groupAge: ageGroup,
    };

    props.onCalculateIncomeTax(incomeInfo);
  };

  return (
    <form onSubmit={handleFormSubmit} className="shrink-0">
      <div className="flex flex-wrap">
        <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-money-green-2 p-5">
          <h4 className="text-2xl font-semibold">SARS Income Tax Calculator</h4>
          <p className="leading-relaxed mt-1 mb-4 text-gray-600">
            Complete this form and we will get back to the <br /> and so on.
          </p>
          <div className="relative w-full mb-3 mt-4">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="taxtYear">
              Select Tax year {taxYear}
            </label>
            <select
              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              name="taxtYear"
              onChange={taxYearHandle}>
              <option value="2022">2022 </option>
              <option value="2021">2021 </option>
              <option value="2020">2020 </option>
            </select>
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="salary">
              Total salary before deduction {totalSalary}
            </label>
            <input
              type="number"
              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              placeholder="Total salary"
              name="salary"
              style={{ transition: "all .15s ease" }}
              onChange={totalSalaryHandler}
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="payCycle">
              Select a pay cycle {payCycle}
            </label>
            <select
              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              name="payCycle"
              onChange={payCycleHandler}>
              <option value="Monthly">Monthly </option>
              <option value="Weekly">Weekly </option>
              <option value="Fortnight">Fortnight </option>
              <option value="Yearly">Yearly </option>
            </select>
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="ageGroup">
              Select age group {ageGroup}
            </label>
            <label className="text-gray-700 mr-5">
              <input
                type="radio"
                value=">65"
                name="ageGroup"
                onChange={ageGroupHandler}
              />
              <span className="ml-1">Under 65</span>
            </label>
            <label className="text-gray-700 mr-5">
              <input
                type="radio"
                value="65-75"
                name="ageGroup"
                onChange={ageGroupHandler}
              />
              <span className="ml-1">65 - 75</span>
            </label>
            <label className="text-gray-700 mr-5">
              <input
                type="radio"
                value="<75"
                name="ageGroup"
                onChange={ageGroupHandler}
              />
              <span className="ml-1">Over 75</span>
            </label>
          </div>
          <div className="mt-6">
            <button
              className="bg-money-green-1 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="submit"
              style={{ transition: "all .15s ease" }}>
              Calculate
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default IncomeTaxForm;
