const BannerBg = (props) => {
  return (
    <div
      className="absolute top-0 w-full h-full bg-center bg-cover"
      style={{
        backgroundImage: `url(${props.bgImage})`,
      }}>
      <span
        id="blackOverlay"
        className="w-full h-full absolute opacity-80 bg-gray-900"></span>
    </div>
  );
};

export default BannerBg;
