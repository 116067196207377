import { useState } from "react";
import smBusiness from "../../assets/sm-business.png";
import benefitImage from "../../assets/images/small-business.jpg";
import BannerText from "../../components/bannerText";
import TaxSummaryCard from "../../components/cards/taxSummaryCard";
import IncomeTaxForm from "../../components/forms/incomeTaxForm";
import BannerBg from "../../UI/bannerBg";
import MembersAssosiation from "../../components/membersAssosiation";
import Benefits from "../../components/benefits";
import ServiesCard from "../../components/cards/serviceCard";

const Home = () => {
  const [showSummary, setShowSummary] = useState(false);
  const [taxInfomation, setTaxInfomation] = useState([]);

  const calculateIncomeTax = (data) => {
    if (data) {
      setShowSummary(true);
      setTaxInfomation([data]);
    }
  };

  const handleHideSummary = () => {
    setShowSummary(false);
  };

  let benefitText = {
    benefit1:
      "Adipisicing elit. Laborum nisi explicabo sed nihil libero beatae. eaque iste deleniti!",
    benefit2:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum nisi sed nihil libero beatae. Velit commodi eaque iste deleniti!",
    benefit3:
      "dolores doloremque ad eum voluptates error molestias rerum corporis quam, explicabo quis tempore itaque reiciendis. Assumenda, unde et officiis suscipit veniam eius",
    benefit4:
      "Dolores, eum deserunt, magni sit perspiciatis aperiam ratione natus, alias sint unde.",
    benefit5:
      "Voluptatum obcaecati voluptatem doloribus! Atque animi, ea veritatis et fuga alias impedit dignissimos, excepturi vitae earum, maxime porro.",
  };

  return (
    <>
      <section
        className="relative flex content-center"
        style={{ height: "90vh" }}>
        <BannerBg bgImage={smBusiness} />
        <div className="content-container relative flex justify-between">
          <IncomeTaxForm onCalculateIncomeTax={calculateIncomeTax} />
          <TaxSummaryCard
            onShowSummary={showSummary}
            taxInfo={taxInfomation}
            onHideSummary={handleHideSummary}
          />
          <BannerText />
        </div>
      </section>
      <section className="content-container">
        <ServiesCard />
      </section>
      <div className=" bg-slate-100 mt-10 pb-5">
        <section className="content-container">
          <Benefits benefitImage={benefitImage} benefitText={benefitText} />
        </section>
        <MembersAssosiation />
      </div>
    </>
  );
};

export default Home;
