import React from "react";

const Pricing = () => {
  return (
    <div className=" content-container" style={{ height: "78vh" }}>
      Pricing page{" "}
    </div>
  );
};

export default Pricing;
